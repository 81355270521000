import { call, put, select, takeLatest } from "redux-saga/effects";
import { createCourseApi, deleteCourseApi, getCoursesApi, updateCourseApi } from "../../helpers/api/courses.api";
import { toastr } from "../actions";
import {
	CREATE_CLASS,
	CREATE_CLASS_SUCCESS,
	DELETE_CLASS,
	DELETE_CLASS_FAILED,
	DELETE_CLASS_SUCCESS,
	GET_ALL_CLASSES,
	GET_ALL_CLASSES_SUCCESS,
	UPDATE_CLASS,
	UPDATE_CLASS_FAILED,
	UPDATE_CLASS_SUCCESS
} from "./actionTypes";

function* getAllClasses({ payload }) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const resp = yield call(getCoursesApi, company);
		yield put({ type: GET_ALL_CLASSES_SUCCESS, payload: resp.courses})
	} catch (e) {
		yield put(toastr({
			title: 'Error getting class list, please try again.',
			type: 'danger'
		}));
	}
}

function* createClass({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const data = {
			name: payload.data.name,
			pricingModels: payload.pricingModels
		}

		const resp = yield call(createCourseApi, company, data);

		yield put({ type: CREATE_CLASS_SUCCESS, payload: resp.course})
		yield put(toastr({
			title: 'Class created successfully.',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Error creating this course, please try again.',
			type: 'danger'
		}));
	}
}

function* updateClass({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const classData = {
			...payload.data,
			pricingModels: payload.pricingModels
		};

		yield call(updateCourseApi, company, payload.id, classData);

		yield put({ type: UPDATE_CLASS_SUCCESS, payload: { id: payload.id, ...classData } })
		yield put(toastr({
			title: 'Class updated successfully.',
			type: 'success'
		}));
	} catch(e) {
		yield put({ type: UPDATE_CLASS_FAILED, message: e.message })
		yield put(toastr({
			title: 'Error updating this class, please try again.',
			type: 'danger'
		}));
	}
}

function* deleteClass({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		yield call(deleteCourseApi, company, payload);

		yield put({ type: DELETE_CLASS_SUCCESS, payload: payload })
	} catch(e) {
		yield put({ type: DELETE_CLASS_FAILED, message: e.message })
		yield put(toastr({
			title: 'Error deleting this class, please try again.',
			type: 'danger'
		}));
	}
}

function* clientsSaga() {
	yield takeLatest(GET_ALL_CLASSES, getAllClasses);
	yield takeLatest(CREATE_CLASS, createClass);
	yield takeLatest(UPDATE_CLASS, updateClass);
	yield takeLatest(DELETE_CLASS, deleteClass);
}

export default clientsSaga;
