import React from 'react';

import { withTranslation } from 'react-i18next';
import moment from 'moment';

import {
	Table
} from 'reactstrap';

const PaymentDetailsInModal = ({payment, t}) => {
	return (
		<Table className="table-responsive ">
		<tbody>
				<tr>
					<td className='tw-medium'>{t("Client")}</td>
					<td>{payment.client.label}</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Date")}</td>
					<td>{moment(payment.createdAt?.seconds*1000, 'x').format("DD/MM/YYYY HH:mm").toString()}</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Price")}</td>
					<td>{payment.price} MKD</td>
				</tr>
			</tbody>
		</Table>
	);
};

export default withTranslation()(PaymentDetailsInModal);
