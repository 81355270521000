export const reportsMenuData = [
	{
		id: "reports",
		label: "Reports",
		icon: "ri-line-chart-line",
		link: "/reports",
		subItems: [
			{
				id: "daily-activity",
				label: "Daily Summary",
				link: "/reports/daily-summary",
				parentId: "reports",
			},
			{
				id: "daily-activity",
				label: "Daily Statistics",
				link: "/reports/daily-statistics",
				parentId: "reports",
			},
			{
				id: "products-list",
				label: "Products",
				link: "/reports/products",
				parentId: "reports"
			},
			{
				id: "services-list",
				label: "Services",
				link: "/reports/services",
				parentId: "reports",
			},
			{
				id: "service-providers-list",
				label: "Service Providers",
				link: "/reports/service-providers",
				parentId: "reports",
			},
		]
	},
];
