import {
	CREATE_SERVICE_PROVIDER,
	UPDATE_SERVICE_PROVIDER,
	DELETE_SERVICE_PROVIDER,
	GET_SERVICE_PROVIDERS,
	UPDATE_SERVICE_PROVIDER_COLOR,
} from "./actionTypes";

export const getServiceProviders = (company = '') => ({
  type: GET_SERVICE_PROVIDERS,
  payload: { company }
});

export const createServiceProvider = (serviceProvider, company = '') => ({
  type: CREATE_SERVICE_PROVIDER,
  payload: { serviceProvider, company }
});

export const updateServiceProvider = (id, serviceProvider, company = '') => ({
	type: UPDATE_SERVICE_PROVIDER,
	payload: { id, serviceProvider, company }
});

export const deleteServiceProvider = (id) => ({
  type: DELETE_SERVICE_PROVIDER,
  payload: { id }
});

export const updateServiceProviderColor = (id, color) => ({
	type: UPDATE_SERVICE_PROVIDER_COLOR,
	payload: { id, color }
});
