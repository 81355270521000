import {
	LOGIN_USER,
	LOGIN_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	API_ERROR,
	RESET_LOGIN_FLAG,
	GET_USER_DOC, GET_USER_DOC_SUCCESS
} from "./actionTypes";

const initialState = {
	user: false,
	errorMsg: "",
	loading: false,
	error: false,
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true,
				error: false,
			};
			break;
		case LOGIN_SUCCESS:
			state = {
				...state,
				loading: false,
				error: false,
				user: action.payload
			};
			break;
		case GET_USER_DOC:
			state = {
				...state,
				loading: true,
				error: false,
			}
			break;
		case GET_USER_DOC_SUCCESS:
			state = {
				...state,
				loading: false,
				error: false,
				user: action.payload
			}
			break;
		case LOGOUT_USER:
			state = { ...state, isUserLogout: false, user: false };
			break;
		case LOGOUT_USER_SUCCESS:
			state = { ...state, isUserLogout: true, user: false };
			break;
		case API_ERROR:
		{
				const noUser = action.payload === "No user found";

				state = {
					...state,
					errorMsg: noUser ? "" :"Invalid email or password",
					loading: !noUser,
					error: !noUser,
					isUserLogout: noUser,
				};
				break;
			}
		case RESET_LOGIN_FLAG:
			state = {
				...state,
				errorMsg: null,
				loading: false,
				error: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default login;
