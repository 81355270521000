export const MODULES = {
	SUBSCRIPTIONS: 'moduleSubscriptions',
	DEPARTMENTS: 'moduleDepartments',
	CLOSE_DAY: 'moduleCloseDay',
	GROUP_ACTIVITIES: 'moduleGroupActivities',
	SINGLETON_ACTIVITIES: 'moduleSingletonActivities',
	PRODUCTS_AND_SERVICES: 'moduleProductsAndServices',
	CLASSES: 'moduleClasses'
}

export const COMPANY_TYPES = {
	DENTAL: 'companyDental',
	SPORTS: 'companySports'
}
