import { APIClient } from "./api_helper";

export const getClientsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/clients`);
}

export const getClientApi = async (companyId, id) => {
	return await new APIClient().get(`/company/${companyId}/clients/${id}`);
}

export const createClientApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/clients`, data);
}

export const updateClientApi = async (companyId, id, data) => {
	return await new APIClient().put(`/company/${companyId}/clients/${id}`, data);
}

