import React, {useEffect}from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { getEvent, selectPurchase, selectTransaction} from '../../../store/actions';
import EventDetailsInModal from './Widgets/EventDetailsInModal';
import PaymentDetailsInModal from './Widgets/PaymentDetailsInModal';
import PurchaseDetailsInModal from './Widgets/PurchaseDetailsInModal';

const types = {
	'appointment': 'Appointment',
	'payment': 'Payment',
	'purchase': 'Product Purchase'
};

const TransactionDetailsModal = ({t, type, id, onCloseClick}) => {
	const dispatch = useDispatch();
	const eventData = useSelector(state => state.Calendar.selectedEvent);
	const purchaseData = useSelector(state => state.Purchases.selected); 
	const paymentData = useSelector(state => state.Transactions.selected);

	useEffect(() => {
		if(type && id) {
			if(type === 'appointment') {
				dispatch(getEvent(id));
			}

			if(type === 'purchase') {
				dispatch(selectPurchase(id));
			}

			if(type === 'payment') {
				dispatch(selectTransaction(id))
			}
		}
		}, [dispatch, type, id]);
	return (
		<Modal isOpen={!!type} toggle={onCloseClick} centered={true}>
			<ModalHeader toggle={onCloseClick}>
				{types[type]} details
			</ModalHeader>
			<ModalBody className="py-3 px-3">
				<div className="mt-2">
					<lord-icon
						src="https://cdn.lordicon.com/gsqxdxog.json"
						trigger="loop"
						colors="primary:#f7b84b,secondary:#f06548"
						style={{ width: "100px", height: "100px" }}
					></lord-icon>
					<div className="pt-2 fs-15">
						{type === 'appointment' && eventData ? (
							<EventDetailsInModal event={eventData} />
						) : null}
						{type === 'purchase' && purchaseData ? (
							<PurchaseDetailsInModal purchase={purchaseData} />
						) : null}
						{type === 'payment' && paymentData ? (
							<PaymentDetailsInModal payment={paymentData} />
						) : null}
					</div>
				</div>
				<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
					<button
						type="button"
						className="btn w-sm btn-light"
						data-bs-dismiss="modal"
						onClick={onCloseClick}
					>
					{t("Close")} 
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default withTranslation()(TransactionDetailsModal);
