import { APIClient } from "./api_helper";

export const getCompaniesApi = async () => {
	return await new APIClient().get("/companies");
}

export const getCompanyByIdApi = async (id) => {
	return await new APIClient().get(`/companies/${id}`);
}

export const createCompanyApi = async (company) => {
	company.status = "MISSING_INVITE";

	return await new APIClient().post("/companies", company);
}

export const updateCompanyApi = async (company) => {
	return await new APIClient().put(`/companies/${company.ID}`, company);
}

export const updateCompanyWorkingHoursApi = async (companyId, workingHours) => {
	return await new APIClient().post(`/company/${companyId}/working-hours`, workingHours);
}
