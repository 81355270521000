import React, {useEffect, useState} from 'react';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Modal, ModalBody, ModalHeader, ListGroup, ListGroupItem} from 'reactstrap';
import CreateTransactionForm from '../../Transactions/Create/form';
import { getClientUnpaidTransactions } from '../../../store/actions';

const icons = {
	'Appointment': 'ri-calendar-event-fill',
	'Product Purchase': 'ri-money-dollar-circle-fill',
};

const AddClientPaymnetModal = ({t, client, isOpen, onCloseClick}) => {
	const dispatch = useDispatch();
	const [price, setPrice] = useState(0);

	let unpaidTransactions = useSelector(state => state.Transactions.unpaidTransactions);

	useEffect(() => {
		if(!unpaidTransactions && client.id) {
			dispatch(getClientUnpaidTransactions(client.id));
		}
		}, [client.id, unpaidTransactions, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(getClientUnpaidTransactions(null));
		}
		}, [dispatch]);

	const totalUnpaid = unpaidTransactions?.reduce((acc, transaction) => parseInt(acc) + parseInt(transaction.amount) - parseInt(transaction.paidAmount || 0), 0) || 0;

	let sortedTransactions = unpaidTransactions?.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);

	if(sortedTransactions) {
		let toBePaid = price;

		sortedTransactions = sortedTransactions.map(transaction => {
			toBePaid -= transaction.amount - (transaction.paidAmount || 0);
			transaction.willBePaid = toBePaid >= 0;
			return transaction;
		});
	}

	const handlePriceChanged = (price) => {
		setPrice(price);
	}

	let sum = 0;
	return (
		<Modal size='xl' isOpen={!!isOpen} toggle={onCloseClick} centered={true}>
			<ModalHeader toggle={onCloseClick}>
				{t("Add payment")}
			</ModalHeader>
			<ModalBody className="py-3 px-3">
				<Row>
				<Col md={6}>
						<CreateTransactionForm
							toggle={onCloseClick}
							isModal={true}
							fixedType="payment"
							max={totalUnpaid}
							onPriceChange={handlePriceChanged}
							client={{ label: client.name, value: client.id }}
						/>
					</Col>
				<Col md={6}>
						<h5 className='mb-4'> {t("List of debts")} - {totalUnpaid} MKD</h5>
						<ListGroup className='unpaid-list-in-modal'>
							{sortedTransactions && sortedTransactions.map((transaction, index) => {
								const classes = transaction.willBePaid ? 'bg-success-subtle' : '';
								const textClasses = transaction.willBePaid ? 'text-success' : 'text-danger';

								sum += parseInt(transaction.amount) - parseInt(transaction.paidAmount || 0);
								return (
									<ListGroupItem key={`unpaid-modal-${index}`} className={classes}>
										<i className={`${icons[transaction.debtType]} align-middle me-2 ${textClasses}`}></i>
										{t(transaction.debtType)} {transaction.target ? " - " + transaction.target : null} - <b>{transaction.amount} MKD </b> ({t("paid")} {transaction.paidAmount ?? 0} MKD) - {moment(transaction.createdAt?.seconds*1000, 'x').format("DD/MMM/YY").toString()} ({sum}MKD)
									</ListGroupItem>
								)}
							)}
						</ListGroup>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default withTranslation()(AddClientPaymnetModal);
