import React from 'react';

import { Card, Col } from 'reactstrap';

import CountUp from 'react-countup';

function ClientTotalEventsWidget({type, className="", text=null, title, icon, notification="", count=null, decimals=0, suffix="", prefix="", badge=""}) {
		return (
				<Card className='mb-3'>
						<Col className={"col-lg " + className}>
								<div className="mt-3 mt-md-0 py-4 px-3">
										<h5 className="text-muted text-uppercase fs-13">{title}<i className={"fs-24 float-end align-middle " + badge}></i></h5>
										<div className="d-flex align-items-center">
												<div className="flex-shrink-0">
														<i className={"display-6 text-muted " + icon}></i>
												</div>
												<div className="flex-grow-1 ms-3">
														<h2 className="mb-0"><span className="counter-value">
																{ type === 'count' ? (
																<CountUp
																		start={0}
																		prefix={prefix}
																		suffix={suffix}
																		end={count}
																		decimals={decimals}
																		duration={1}
																/>
																) : null }

																{ type === 'text' ? text : null } 
														</span>
														</h2>
												</div>
										</div>
										<div className="text-muted mt-3">
												{notification}
										</div>
								</div>
						</Col>
				</Card>
		);
}

export default ClientTotalEventsWidget;
