import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import AllClientTransactions from './AllClientTransactions';
import { getServiceProviders, selectClient } from '../../../store/actions';

const ClientView = ({t}) => {
	const dispatch = useDispatch();
	const serviceProviders = useSelector(state => state.ServiceProviders.list);

	useEffect(() => {
		if(!serviceProviders) {
			dispatch(getServiceProviders());
		}
		}, [serviceProviders, dispatch]);

	const [activeTab, setActiveTab] = useState('1');


	const { id } = useParams();
	const client = useSelector(state => state.Clients.selected);

	useEffect(() => {
		if(!client) {
			dispatch(selectClient(id));
		}
		}, [id, client, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(selectClient(null));
		}
		}, [dispatch]);

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	document.title = t("Client Profile | Termin");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="profile-wrapper ">
						<div className="p-2">
							<h3 className="mb-1">{client.name}</h3>
							<div className="hstack text-muted gap-1">
								<div className="me-2">
									<i className="ri-map-pin-user-line me-1 fs-16 align-bottom text-body"></i>
									{client.address}, {client.city}
								</div>
							</div>
						</div>
					</div>

					<Row>
					<Col lg={12}>
							<div>
								<div className="d-flex profile-wrapper">
									<Nav pills className="gap-2 gap-lg-3 flex-grow-1 nav-tabs-custom rounded mt-0 card-header-tabs border-bottom-0"
										role="tablist">
										<NavItem>
											<NavLink
												href="#overview-tab"
											className={classnames({ active: activeTab === '1' }, "fs-14")}
												onClick={() => { toggleTab('1'); }}
												>
												<i className="d-none ri-airplay-fill d-inline-block"></i> <span
													className="d-md-inline-block">{t("Overview")}</span>
											</NavLink>
										</NavItem>
									</Nav>
								</div>

								<Row>
								<Col xxl={3} md={4}>
										<Card>
											<CardBody>
												<h5 className="card-title mb-3">{t("Info")}</h5>
												<div className="table-responsive">
													<Table className="table-borderless mb-0">
														<tbody>
															<tr>
															<th className="ps-0" scope="row">{t("Full name")}</th>
																<td className="text-muted">{client.name}</td>
															</tr>
															<tr>
															<th className="ps-0" scope="row">{t("Phone")}</th>
																<td className="text-muted">{client.phone}</td>
															</tr>
															<tr>
															<th className="ps-0" scope="row">{t("E-mail")}</th>
																<td className="text-muted">{client.email}</td>
															</tr>
															<tr>
															<th className="ps-0" scope="row">{t("Address")}</th>
																<td className="text-muted">{client.address}, {client.city}</td>
															</tr>
															<tr>
															<th className="ps-0" scope="row">{t("Joining Date")}</th>
																<td className="text-muted">{moment(client.createdAt?.seconds*1000, 'x').format("DD/MM/YYYY").toString()}</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</CardBody>
										</Card>
									</Col>
								<Col xxl={9} md={8}>
										<TabContent activeTab={activeTab} className="text-muted">
											<TabPane tabId="1">
												{client ? (
													<AllClientTransactions isActive={true} client={client} clientId={id}/>
												) : null}
											</TabPane>
										</TabContent>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(ClientView);

