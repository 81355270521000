import { CREATE_COST, DELETE_COST, GET_COSTS } from "./actionTypes";

export const getCosts = (options = {}) => {
  const { search = '', company = '' } = options;
  return {
    type: GET_COSTS,
    payload: {
      search,
      company
    },
  }
}

export const createCost = (data, company = '') => {
  return {
    type: CREATE_COST,
    payload: {
      data,
      company,
    },
  }
}

export const deleteCost = (id, company = '') => {
  return {
    type: DELETE_COST,
    payload: {
      id,
      company,
    },
  }
}
