import {
	GET_COMPANIES,
	CREATE_COMPANY,
	CREATE_COMPANY_USER,
	DESELECT_COMPANY,
	SELECT_LOGGED_IN_COMPANY,
	UPDATE_LOGGED_IN_COMPANY,
	SELECT_COMPANY,
	UPDATE_COMPANY,
    CLOSE_DAY,
    RESET_COMPANY,
    SELECT_LOGGED_IN_COMPANY_SUCCESS,
} from "./actionTypes";

export const closeDay = () => {
	return {
		type: CLOSE_DAY
	}
}

export const getCompanies = () => {
	return {
		type: GET_COMPANIES
	}
}

export const selectCompany = (id) => {
	return {
		type: SELECT_COMPANY,
		payload: id
	}
}

export const createCompany = (company) => {
	return {
		type: CREATE_COMPANY,
		payload: company
	}
}

export const deselectCompany = () => {
	return {
		type: DESELECT_COMPANY
	};
}

export const selectLoggedInCompany = (companyId) => {
	return {
		type: SELECT_LOGGED_IN_COMPANY,
		payload: companyId
		};
}

export const selectLoggedInCompanySuccess = (data) => {
	return {
		type: SELECT_LOGGED_IN_COMPANY_SUCCESS,
		payload: data
	}
}

export const createCompanyUser = (user) => {
	return {
		type: CREATE_COMPANY_USER,
		payload: user
	};
}

export const updateCompany = (data) => {
	return {
		type: UPDATE_COMPANY,
		payload: data,
	}
}

export const updateLoggedInCompany = (data, companyId = null) => {
	return {
		type: UPDATE_LOGGED_IN_COMPANY,
		payload: {
			companyId,
			data,
		}
	};
}

export const resetCompany = (id) => {
	return {
		type: RESET_COMPANY,
		payload: id
	}
}
