import { call, put, select, takeEvery } from "redux-saga/effects";
import { createCourseGroupApi, deleteCourseGroupApi, getAllGroupsApi, updateCourseGroupApi } from "../../helpers/api/groups.api";

import {
	assignAttendeesToGroupInDB,
	getGroupAttendeesFromDB,
} from "../../helpers/firebase/groups.firebase";
import { toastr } from "../actions";

import {
	ASSIGN_ATTENDEES_TO_GROUP,
	ASSIGN_ATTENDEES_TO_GROUP_FAILURE,
	ASSIGN_ATTENDEES_TO_GROUP_SUCCESS,
	CREATE_GROUP,
	CREATE_GROUP_FAILURE,
	CREATE_GROUP_SUCCESS,
	DELETE_GROUP,
	DELETE_GROUP_FAILURE,
	DELETE_GROUP_SUCCESS,
	GET_GROUPS,
	GET_GROUPS_FAILURE,
	GET_GROUPS_SUCCESS,
	GET_GROUP_ATTENDEES,
	GET_GROUP_ATTENDEES_FAILURE,
	GET_GROUP_ATTENDEES_SUCCESS,
	UPDATE_GROUP,
	UPDATE_GROUP_FAILURE,
	UPDATE_GROUP_SUCCESS
} from "./actionTypes";

function* getGroups() {
	try {
		const company = yield select(state => state.Company.loggedInCompany.ID);

		const resp = yield call(getAllGroupsApi, company);

		yield put({ type: GET_GROUPS_SUCCESS, payload: resp.groups });
	} catch (error) {
		yield put({ type: GET_GROUPS_FAILURE, error });
	}
}

function* createGroup({ payload }) {
	try {
		const company = yield select(state => state.Company.loggedInCompany.ID);
		const {data} = payload;

		yield call(createCourseGroupApi, company, data.courseId, data);

		yield put({ type: CREATE_GROUP_SUCCESS });
	} catch (error) {
		yield put({ type: CREATE_GROUP_FAILURE, error });
	}
}

function* assignAttendeesToGroupSaga({ payload }) {
	try {
		let user = yield select(state => state.Login.user);

		const { groupId, attendeesList } = payload;

		yield call(assignAttendeesToGroupInDB, user.companyId, groupId, attendeesList);

		yield put({ type: ASSIGN_ATTENDEES_TO_GROUP_SUCCESS });
	} catch (error) {
		yield put({ type: ASSIGN_ATTENDEES_TO_GROUP_FAILURE, error });
	}
}

function* deleteGroup({ payload }) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		yield call(deleteCourseGroupApi, company, payload.course.ID, payload.ID)

		yield put({ type: DELETE_GROUP_SUCCESS, payload: payload.ID});
		yield put(toastr({
			type: 'success',
			title: 'Group deleted successfully'
		}));
	} catch (error) {
		yield put(toastr({
			type: 'error',
			title: 'Error deleting group'
		}));
		yield put({ type: DELETE_GROUP_FAILURE, error });
	}
}

function* getGroupAttendees({ payload }) {
	try {
		let user = yield select(state => state.Login.user);

		const resp = yield call(getGroupAttendeesFromDB, user.companyId, payload);

		yield put({ type: GET_GROUP_ATTENDEES_SUCCESS, payload: {groupId: payload, attendees: resp}});
	} catch (error) {
		yield put({ type: GET_GROUP_ATTENDEES_FAILURE, error });
	}
}

function* updateGroup({payload}) {
	try{
		const { ID, companyId } = payload.data;
		const data = {
			ID: payload.data.ID,
			courseId: payload.data.courseId,
			name: payload.data.name,
		};

		const resp = yield call(updateCourseGroupApi, companyId, ID, data);

		yield put({ type: UPDATE_GROUP_SUCCESS, payload: resp.group});
		yield put(toastr({
			type: 'success',
			title: 'Group updated successfully'
		}));
	} catch (error) {
		console.log(error);
		yield put({ type: UPDATE_GROUP_FAILURE, error });
	}
}

export default function* groupsSaga() {
	yield takeEvery(GET_GROUPS, getGroups);
	yield takeEvery(CREATE_GROUP, createGroup);
	yield takeEvery(UPDATE_GROUP, updateGroup);
	yield takeEvery(DELETE_GROUP, deleteGroup);
	yield takeEvery(ASSIGN_ATTENDEES_TO_GROUP, assignAttendeesToGroupSaga);
	yield takeEvery(GET_GROUP_ATTENDEES, getGroupAttendees);
}

