import React from 'react';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';

const PurchaseDetailsInModal = ({t, purchase}) => {
		return (
				<Table className="table-responsive ">
						<tbody>
								<tr>
										<td className='tw-medium'>{t("Client")}</td>
										<td>{purchase.client.label}</td>
								</tr>
								<tr>
										<td className='tw-medium'>{t("Date")}</td>
										<td>{moment(purchase.createdAt?.seconds*1000, 'x').format("DD/MM/YYYY HH:mm").toString()}</td>
								</tr>
								<tr>
										<td className='tw-medium'>{t("Price")}</td>
										<td>{purchase.price} MKD</td>
								</tr>
								<tr>
										<td className='tw-medium'>{t("Products")}</td>
										<td>{
												purchase.services && purchase.services.map((service, index) => (
														<div key={`service-${index}`}>{service.service.label} x {service.amount}</div>
												))
										}</td>
								</tr>
						</tbody>
				</Table>
		);
};

export default withTranslation()(PurchaseDetailsInModal);
