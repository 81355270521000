import React from 'react';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { useSelector } from 'react-redux';

const EventDetailsInModal = ({t, event}) => {
	const serviceProvider = useSelector(state => state.ServiceProviders.list.find(sp => sp.id === event.serviceProvider));

	const formatDuration = (duration) => {
		if(duration >= 60) {
			return `${Math.floor(duration / 60)} h ${duration % 60} min`;
		}

		return `${duration} min`;
	}

	return (
		<Table className="table-responsive ">
		<tbody>
				<tr>
					<td className='tw-medium'>{t("Service Provider")}</td>
					<td>{serviceProvider?.name}</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Client")}</td>
					<td>{event.clientName}</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Date")}</td>
					<td>{moment(event.start?.seconds*1000, 'x').format("DD/MM/YYYY").toString()}</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Time")}</td>
					<td>{moment(event.start?.seconds*1000, 'x').format("HH:mm").toString()} - {moment(event.end?.seconds*1000, 'x').format("HH:mm").toString()} </td>
				</tr>
				{ event.duration ? (
					<tr>
						<td className='tw-medium'>{t("Duration")}</td>
						<td>{formatDuration(event.duration)}</td>
					</tr>
					) : null }
				<tr>
					<td className='tw-medium'>{t("Price")}</td>
					<td>{event.price} MKD</td>
				</tr>
				<tr>
					<td className='tw-medium'>{t("Services")}</td>
					<td>{
						event.services && event.services.map((service, index) => (
							<div key={`service-${index}`}>{service.service.label} x {service.amount}</div>
						))
					}</td>
				</tr>
			</tbody>
		</Table>
	);
};

export default withTranslation()(EventDetailsInModal);
