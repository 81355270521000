import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import Layout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import { loginSuccess } from '../store/actions';
import { getLoggedinUser } from '../helpers/api/api_helper';

const Index = () => {
	const dispatch = useDispatch();
	const [user, setUser] = useState(getLoggedinUser());

	const companyType = useSelector((state) => state.Company.loggedInCompany?.type);
	const loggedInUser = useSelector((state) => state.Login.user);

	useEffect(() => {
		if(!loggedInUser && user) {
			dispatch(loginSuccess(user));
		} else if(loggedInUser && !user) {
			setUser(loggedInUser);
		}
	}, [loggedInUser, user]);

	const authRoutes = authProtectedRoutes(user?.role ?? 'none', companyType);

	return (
		<>
			<Routes>
				<Route>
					{publicRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<NonAuthLayout>
									{route.component}
								</NonAuthLayout>
							}
							key={idx}
							exact={true}
						/>
					))}
				</Route>

				<Route>
					{authRoutes.map((route, idx) => {
						return (
							<Route
								path={route.path}
								element={
									<AuthProtected>
										<Layout>{route.component}</Layout>
									</AuthProtected>}
								key={idx}
								exact={true}
							/>
						)}
					)}
				</Route>
			</Routes>
		</>
		);
};

export default Index;
