import { useSelector } from "react-redux";

const useCompanyModules = () => {
	const data = useSelector(state => state.Company.loggedInCompany);

	const hasModule = (name) => {
		if(!data) {
			return false;
		}

		if(!data.modules || !Array.isArray(data.modules)) {
			return false;
		}

		return data.modules.includes(name);
	}

	return {
		hasModule,
		modules: data?.modules ?? []
	}
}

export default useCompanyModules;

