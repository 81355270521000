import React from 'react';
import {Button, Col, Form, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import { withTranslation } from 'react-i18next';
import {useFormik} from "formik";
import * as Yup from "yup";
import {Navigate} from 'react-router-dom';
import { createCost } from '../../../store/actions';

const CreateCostsForm = ({isModal = false, toggle, redirectTo = '', t}) => {
	const dispatch = useDispatch();

	const finishedCreate = useSelector(state => state.Costs.finishedCreate);
	const loading = false;
	const [data, setData] = React.useState({
		note: '',
		amount: '',
		currency: 'MKD',
	});

	const validation = useFormik({
		initialValues: data,
		enableReinitialize: true,
		validationSchema: Yup.object({
			note: Yup.string().required(t("Note is required")),
			amount: Yup.string().required(t("Amount is required")),
			currency: Yup.string().required(t("Currency is required")),
		}),
		onSubmit: (values) => {
			dispatch(createCost(values));
		}
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		if (name.includes('.')) {
			const [parent, child] = name.split('.');
			const newData = data;
			newData[parent][child] = value;

			return setData(newData);
		}

		setData({...data, [name]: value});
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		validation.handleSubmit();
	}

	return (
		<Form className="form-steps" onSubmit={handleSubmit}>
			{finishedCreate && redirectTo ? (
				<Navigate to={redirectTo} replace={true} />
				) : null}
			<Row>
				<Col md={12} xl={12} className="mb-3">
					<div className="mb-3">
						<Label for="note" className="form-label">{t("Note")}</Label>
						<Input
							type="textarea"
							name="note"
							className="form-control"
							id="note"
							placeholder={t("Note")} 
							onChange={handleChange}
						/>
					</div>
				</Col>
				<Col md={6}>
					<div className="mb-3">
						<Label for="amount" className="form-label">{data.currency} {t("Amount")}</Label>
						<Input
							name="amount"
							type="number"
							className="form-control d-block"
							aria-label="Default select example"
							onBlur={validation.handleBlur}
							invalid={validation.errors.amount && validation.touched.amount}
							onChange={handleChange}
							value={data.amount}
						></Input>
						{validation.errors.amount && validation.touched.amount? (
							<FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
						) : null}
					</div>
				</Col>
				<Col md={6}>
					<div className="mb-3">
						<Label for="currency" className="form-label">{t("Currency")}</Label>
						<Input
							name="currency"
							type="select"
							className="form-control d-block mb-3"
							aria-label="Default select example"
							onBlur={validation.handleBlur}
							invalid={validation.errors.currency && validation.touched.currency}
							onChange={handleChange}
							value={data.currency}
							>
							<option value="MKD">MKD</option>
							<option value="EUR">EUR</option>
						</Input>
					</div>
				</Col>
				<Col xs={12}>
					<div className="text-end">
						{
							isModal ? (
								<button type="button" onClick={toggle} className="btn btn-ghost-dark" style={{marginRight: 3}}>Cancel</button>
							) : null
						}
						<Button type="submit" color="primary" className="btn-load" disabled={loading}>
							<span className="d-flex align-items-center">
								<span className={"flex-grow-1 " + (loading ? "me-2" : "")}>
									{t("Create")}	
								</span>
								{loading ? ( <Spinner size="sm" className="flex-shrink-0" role="status" /> ) : null}
							</span>
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default withTranslation()(CreateCostsForm);
