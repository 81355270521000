import React from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const icons = {
	"Appointment": "ri-calendar-event-fill",
	"Product Purchase": "ri-shopping-cart-fill",
};
const TimelineElement = ({t, transaction, select}) => {
	const handleSelect = () => {
		if(transaction.debtType === "Appointment") {
			select('appointment', transaction.eventId);
		} else if(transaction.debtType === "Product Purchase") {
			select('purchase', transaction.purchaseId);
		} else if(transaction.type === 'payment') {
			select('payment', transaction.id);
		}
	}

	let paidClass = transaction.paid ? "success" : "danger";
	const payment = transaction.type === 'payment';
	if(payment) {
		paidClass = "success";
	}

	const title = transaction.debtType || "Payment";

	return (
		<div className="accordion-item border-0 hover-bg">
			<div className="accordion-header" id={"heading-"+transaction.id}>
				<Link to="#" onClick={handleSelect} className="accordion-button p-2 shadow-none" id={"collapse"+transaction.id}>
					<div className="d-flex">
						<div
							className="flex-shrink-0 avatar-xs">
							<div
								className={`avatar-title bg-light text-${paidClass} rounded-circle`}>
								<i
									className={icons[transaction.debtType] ?? 'ri-money-dollar-circle-fill'}></i>
							</div>
						</div>
						<div
							className="flex-grow-1 ms-3">
							<h6
								className="fs-14 mb-1">
								{t(title)} {transaction.target ? " - " + transaction.target : ""}
							</h6>
							<small
								className="text-muted d-block"> {t("Created on")} <b>{moment(transaction.createdAt?.seconds*1000, 'x').format("DD-MMM-YYYY HH:mm").toString()}</b></small>
							{ !payment && (
								<small
									className="text-muted"> {t("Status")}: {transaction.paid ? (<span className="text-success">{t("Paid")}</span>) : (<span className="text-danger">{t("Not paid")}</span>)}</small>
							)}
							<span className="d-block text-muted"> 
								<span>{t("Price")}: </span>
								<b className='text-primary'>{transaction.amount} MKD</b>

								{ transaction.type === 'payment' && (
									<span> ({t("Receipt")}: {transaction.receipt ? `${t("Yes")} - ${transaction.receiptAmount} MKD` : t("No")})</span>
								)}
							</span>
						</div>
					</div>
				</Link>
			</div>
		</div>

	);
};

export default withTranslation()(TimelineElement);
