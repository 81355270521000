import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	Col, Row, Card, CardHeader, 
	CardBody, Button
} from 'reactstrap';

import { getClientTransactions } from '../../../store/actions';
import TransactionDetailsModal from './TransactionDetailsModal';
import ClientTotalEventsWidget from './Widgets/ClientTotalEventsWidget';
import TimelineElement from './Widgets/TimelineElement';
import AddClientPaymentModal from './AddClientPaymentModal';
import useDayClosedModule from '../../../Components/Hooks/useDayClosed';

const AllClientTransactions = ({t, client, clientId, isActive}) => {
	const dispatch = useDispatch();
	const transactions = useSelector(state => state.Transactions.allTransactions);
	const [selected, setSelected] = useState({
		transaction: null,
		type: null
	});
	const [addPayment, setAddPayment] = useState(false);
	const {isDayClosed} = useDayClosedModule();

	const debt = (client.totalDue) - (client.totalPaid || 0)

	useEffect(() => {
		if(isActive && !transactions) {
			dispatch(getClientTransactions(clientId));
		}
		}, [transactions, isActive, dispatch, clientId]);

	const parseMinutes = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return `${hours}h ${remainingMinutes}m`;
	}

	const handleSelect = async (type, id) => {
		setSelected({
			transaction: id,
			type
		});
	}

	return (
		<Row>
			<Col lg={8}>
				<Card>
					<CardHeader className="align-items-center d-flex">
						<h4 className="card-title mb-0  me-2">{t("Client history")}</h4>
					</CardHeader>
					<CardBody>
						<div className="profile-timeline">
							<div className="accordion accordion-flush" id="todayExample">
								{transactions && transactions.map((transaction, index) => (
									<TimelineElement select={handleSelect} key={`transaction-${index}`} transaction={transaction} />
								))}
							</div>
						</div>
					</CardBody>
				</Card>
			</Col>

			<Col lg={4}>
				{isActive && (
					<>
						<ClientTotalEventsWidget 
							key={"totalDebt"}
							title={t("Total Debt")}
							icon={"ri-bill-line"}
							className={debt ? "bg-danger-subtle" : "bg-success-subtle"}
							loading={false}
							count={debt}
							suffix={" MKD"}
							notification={(
								<>
									{ debt ? (
										<>
											{ !isDayClosed ? (
												<Button color="info" className="btn-sm" onClick={() => setAddPayment(true)}>{t("Add payment")}</Button>
											) : (<span className="text-danger">{t("Day is closed. Cannot add payment today.")}</span>) 
											}
										</>
										) : null
									}
								</>
							)}
							type={"count"}
						/>
						<ClientTotalEventsWidget 
							key={"totalEvents"}
							title={t("Total appointments")}
							icon={"ri-calendar-event-line"}
							loading={false}
							count={client.totalEvents}
							type={"count"}
						/>
						<ClientTotalEventsWidget
							key={"totalPaid"}
							title={t("Total spent")}
							suffix={"MKD"}
							icon={"ri-money-dollar-circle-line"}
							loading={false}
							count={client.totalPaid}
							type={"count"}
						/>
						<ClientTotalEventsWidget
							key={"totalPurchases"}
							title={t("Total purchases")}
							icon={"ri-store-2-line"}
							loading={false}
							count={client.totalPurchases}
							type={"count"}
						/>
						{ client.totalEvents > 0 && (
							<ClientTotalEventsWidget
								key={"averageDuration"}
								title={t("Average appointment duration")}
								icon={"ri-time-line"}
								loading={false}
								text={parseMinutes(parseInt(client.totalDuration / client.totalEvents))}
								type={"text"}
							/>
						)}
					</>
				)}
			</Col>
			<TransactionDetailsModal type={selected.type} id={selected.transaction} onCloseClick={() => setSelected({transaction: null, type: null})} />	
			<AddClientPaymentModal isOpen={addPayment} onCloseClick={() => setAddPayment(false)} client={client} />
		</Row>
		);
}

export default withTranslation()(AllClientTransactions);

