import { addDays, addHours, format, isAfter } from "date-fns";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import useCompanyModules from "./useCompanyModules";

const useDayClosedModule = () => {
  const { hasModule } = useCompanyModules();
  const closed = useSelector(state => state.Company?.loggedInCompany?.dayClosed || false);
  const workingHours = useSelector(state => state.Company?.loggedInCompany?.workingHours || false);

  const [isClosed, setIsClosed] = useState(false);

  const checkIsClosed = useCallback(() => {
    if (!hasModule("moduleCloseDay")) {
      return false;
    }

    if (!closed) {
      return false;
    }

    if (!workingHours) {
      return false;
    }

    let date = addDays(closed.toDate(), 1);
    let todayWorkingHours = workingHours.find(wh => wh.day === format(date, "EEEE"));
    let iterations = 0;

    while (todayWorkingHours && todayWorkingHours.closed && iterations < 7) {
      date = addDays(date, 1);
			//eslint-disable-next-line
      todayWorkingHours = workingHours.find(wh => wh.day === format(date, "EEEE"));
      iterations++;
    }

    if (!todayWorkingHours || iterations >= 7) {
      return true; // If we couldn't find an open day within a week, assume closed
    }

    if (isAfter(new Date(), addHours(new Date(format(date, "yyyy-MM-dd") + " " + todayWorkingHours.start), -1))) {
      return false;
    }

    return true;
  }, [hasModule, closed, workingHours]);

  useEffect(() => {
    setIsClosed(checkIsClosed());
		//eslint-disable-next-line
  }, [closed]);

  return isClosed;
};

export default useDayClosedModule;
