import { CREATE_PURCHASE, DELETE_PURCHASE, GET_PURCHASES, UPDATE_PURCHASE, SELECT_PURCHASE } from "./actionTypes";

export const getPurchases = (options = {}) => {
  const { search = '', company = '' } = options;
  return {
    type: GET_PURCHASES,
    payload: {
      search,
      company
    },
  }
}

export const createPurchase = (data, company = '') => {
  return {
    type: CREATE_PURCHASE,
    payload: {
      data,
      company,
    },
  }
}

export const selectPurchase = (id, company = '') => {
	return {
		type: SELECT_PURCHASE,
		payload: {
			id,
			company,
		},
	}
}

export const updatePurchase = (id, data, originalData, company = '') => {
  return {
    type: UPDATE_PURCHASE,
    payload: {
						id,
      data,
						originalData,
      company,
    },
  }
}

export const deletePurchase = (id, company = '') => {
  return {
    type: DELETE_PURCHASE,
    payload: {
      id,
      company,
    },
  }
}
