import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLoggedinUser } from "../helpers/api/api_helper";
import { authProtectedRoutes } from "../Routes/allRoutes";
import menuData from './MenuData';
const Navdata = () => {
	const defaultMenuState = {}
	const [menuState, setMenuState] = useState(defaultMenuState);
	const user = getLoggedinUser();
	const companyType = useSelector((state) => state.Company.loggedInCompany?.type);
	const [authRoutes, setAuthRoutes] = useState([]);

	useEffect(() => {
		if (!user) {
			return;
		}

		if (authRoutes.length > 0) {
			return;
		}

		if (user.role !== "admin" && !companyType) {
			return;
		}

		setAuthRoutes(authProtectedRoutes(user?.role ?? 'none', companyType));
	}, [user, companyType, authRoutes]);

	function updateIconSidebar(e) {
		if (e && e.target && e.target.getAttribute("subitems")) {
			const ul = document.getElementById("two-column-menu");
			const iconItems = ul.querySelectorAll(".nav-icon.active");
			let activeIconItems = [...iconItems];
			activeIconItems.forEach((item) => {
				item.classList.remove("active");
				var id = item.getAttribute("subitems");
				if (document.getElementById(id))
					document.getElementById(id).classList.remove("show");
			});
		}
	}

	let menuItems = [
		{
			label: "Menu",
			isHeader: true,
		},
		...menuData,
	].map((menuItem, index) => {
		// skip first item
		if (index === 0) {
			return menuItem;
		}

		// if no authenticated user, remove the menu item (impossible to get to this route)
		if (!user) {
			return null;
		}

		// set up event handler for each parent menu item
		menuItem = {
			...menuItem,
			click: function(e) {
				e.preventDefault();
				setMenuState({ ...defaultMenuState, [menuItem.id]: true });
				updateIconSidebar(e);
			},
			stateVariables: menuState[menuItem.id] ?? false
		}

		// if menuItem has subItems, check if any of the subItems is in authRoutes. Filter out subItems that are not in authRoutes
		if (menuItem.subItems) {
			menuItem.subItems = menuItem.subItems.filter((subItem) => {
				return authRoutes.some((route) => {
					const routeName = menuItem.link + subItem.link;

					if (!route.role?.includes(user.role)) {
						return false;
					}

					if(route.companies && !route.companies?.includes(companyType)) {
						return false;
					}

					let check = false;
					if (routeName === route.path) {
						check = true;
					}

					if (subItem.link === route.path) {
						check = true;
					}

					return check
				});
			});

			if (menuItem.subItems.length === 0) {
				return null;
			}
		}

		// if menuItem has no subItems, check if menuItem is in authRoutes. Filter out menuItems that are not in authRoutes
		if (!menuItem.subItems) {
			const check = authRoutes.some((route) => {
				return menuItem.link === route.path && route.role?.includes(user.role);
			});

			if (!check) {
				return null;
			}
		}

		return menuItem;
	});

	// remove empty array elements
	menuItems = menuItems.filter((menuItem) => menuItem);

	return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
